.App-logo2 {
    height: 40vmin;
    pointer-events: none;
  }


  .LordPic{
    border: dashed red;
    height: 40vmin;
  }


  .container2{
    background-image: url("https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350");
  }


  .App2{
    text-align: center;

  }
  
  .App-logo2 {
    height: 60vmin;
    pointer-events: none;

  }
  
  .App-header2 {
   

    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



  }
  

  

  