.fp-watermark {
    display: none;
}

@media (min-width: 1200px) {
    .px-xl-7 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }
}
